/* ------------------ Text Animation */
.animated-gradient-text {
  animation: animatedSlideBackground 15s infinite linear alternate-reverse;
}

@keyframes animatedSlideBackground {
  to {
    background-position: -100%;
  }
}

/* ------------------ Fades */

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.delay-fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out 0.5s forwards;
  animation-delay: 1s;
}

.reverse {
  transform: rotateY(180deg);
}
