/* ----- React Player ------ */
.player-wrapper {
  height: 100%;
  width: 100%;
}
.react-player {
  position: relative;
}

.react-player > video {
  position: absolute;
  object-fit: cover;
  transform: scale(1.01);
}
