@import './animation.css';
@import './base.css';
@import './elements.css';
@import './fonts.css';
@import './gleap.css';
@import './privy.css';
@import './reactPlayer.css';
@import './scrollbar.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 15px;

  @media (min-height: 801px) {
    font-size: 16px;
  }
}

html,
body {
  @apply overscroll-none;
}

@layer base {
  :root {
    --primary: 81 104 222;
    --accent: 94 252 214;
    --danger: 252 94 94;
    --buttonPrimary: 94 252 214;
    --buttonSecondary: 27 26 83;
  }

  .bg-rainbow {
    background: linear-gradient(
      62.54deg,
      #ffe5aa -34.14%,
      #ffb383 -6.25%,
      #e37790 21.64%,
      #5e5bed 49.53%,
      #5efcd6 77.42%,
      #5e5bed 105.3%,
      #e37790 133.19%,
      #ffb382 161.08%,
      #ffe4aa 188.97%
    );
  }

  .border-rainbow {
    @apply relative rounded-xl;

    &:before {
      @apply content-[''] border-[3px] border-transparent absolute inset-0 rounded-xl z-20 pointer-events-none;

      background: radial-gradient(
        104.4% 205.69% at 50% 50.18%,
        #ffe5aa 0%,
        #ffb383 12.5%,
        #e37790 25%,
        #5e5bed 37.5%,
        #5efcd6 50%,
        #5e5bed 62.5%,
        #e37790 75%,
        #ffb382 87.5%,
        #ffe4aa 100%
      );

      mask:
        linear-gradient(white, white) padding-box,
        linear-gradient(white, white);
      mask-composite: exclude;
    }
  }
}
