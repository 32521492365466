#privy-dialog {
  pointer-events: none;
}

#privy-dialog-backdrop {
  background: rgba(10, 13, 36, 0.2);
  backdrop-filter: blur(20px);
}

#privy-modal-content {
  box-shadow: none !important;
  animation: fadeUp 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  pointer-events: auto;
}

#privy-dialog,
:root {
  --privy-color-background: #0f122c;
  --privy-color-background-2: #1f244c;
  --privy-color-foreground-3: #7278a1;
  --privy-color-foreground-4: #7278a1;
}

.gIYbIA {
  padding: 40px 10px 50px !important;
}

.sc-cPiKLX {
  opacity: 0 !important;
}

.sc-eZkCL img {
  max-width: 250px !important;
}
