/* ----- Gradient Texts ------ */

.text-stroke {
  -webkit-text-stroke: 0.85rem transparent;
}

.gradient-background-position {
  background-position-y: 35%;
}

.gradient-text {
  background-image: linear-gradient(
    to right,
    #ffe5aa,
    #ffb383,
    #e37790,
    #5e5bed,
    #5efcd6,
    #5e5bed,
    #e37790,
    #ffb383,
    #ffe5aa
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 200%;
  background-position: 100%;
}

.personal-room-text {
  background-image: linear-gradient(to right, #5e5aed, #ffb382, #5e5aed);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 200%;
  background-position: 100%;

  animation: animatedSlideBackground 6s infinite linear reverse;
}

.gamenight-room-text {
  background-image: linear-gradient(
    to right,
    #ffe5aa,
    #ffb383,
    #e37790,
    #5e5bed,
    #5efcd6,
    #5e5bed,
    #e37790,
    #ffb383,
    #ffe5aa
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 200%;
  background-position: 100%;
  animation: animatedSlideBackground 6s infinite linear reverse;
}

/* ----- Toggle ------ */

.toggle-parent .toggle {
  background:
    linear-gradient(#0a0d24, #0a0d24) padding-box,
    radial-gradient(
        58.33% 58.33% at 100% 46.15%,
        rgba(252, 94, 94, 0.28) 0%,
        rgba(252, 94, 94, 0.05) 100%
      )
      border-box;
}

.toggle-parent:hover .toggle {
  background:
    linear-gradient(#0a0d24, #0a0d24) padding-box,
    radial-gradient(
        58.33% 58.33% at 100% 46.15%,
        rgba(252, 94, 94, 0.48) 0%,
        rgba(252, 94, 94, 0.05) 100%
      )
      border-box;
}

/* ----- Popover ------ */

.popover-background {
  background: linear-gradient(180deg, rgba(94, 90, 237, 0.7) 0%, rgba(94, 90, 237, 0.9) 100%),
    rgba(184, 188, 224, 0.3);
  box-shadow: 0px 24px 44px 0px rgba(14, 18, 47, 0.5);
}
.popover-background::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.popover-divider-background {
  background: linear-gradient(0deg, rgba(94, 90, 237, 0.2) 0%, rgba(94, 90, 237, 0.2) 100%),
    rgba(14, 18, 47, 0.1);
}

.gamenightschedule-layeredbg {
  background: linear-gradient(180deg, rgba(94, 90, 237, 0.3) 0%, rgba(94, 90, 237, 0.18) 100%),
    rgba(14, 18, 47, 0.8);
}

.gamenightschedule-layeredbg-hover {
  background: linear-gradient(180deg, rgba(94, 90, 237, 0.5) 0%, rgba(94, 90, 237, 0.3) 100%),
    rgba(14, 18, 47, 0.8);
}

.landing-cursor {
  cursor:
    url('/common/icons/landing-cursor.webp') 22 22,
    auto;
}

.room-container {
  @apply relative rounded-2.5xl overflow-hidden bg-gradient-to-br from-[#2F3C80] to-[#212B5A];
}

.room-container:before {
  @apply border border-transparent absolute inset-0 rounded-2.5xl;
  background: linear-gradient(171deg, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.03))
    border-box;
  content: '';
  mask:
    linear-gradient(white, white) padding-box,
    linear-gradient(white, white);
  mask-composite: exclude;
}

.menu-container {
  @apply bg-gradient-to-br from-[#4153B1] to-[#3A4A9D] border border-[#5F72D4];
}

.gradient-border-mask {
  @apply relative;
}

.gradient-border-mask:before {
  @apply border border-transparent absolute inset-0;
  content: '';
  mask:
    linear-gradient(white, white) padding-box,
    linear-gradient(white, white);
  mask-composite: exclude;
}
