html,
body {
  @apply font-sans;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  background: #070918;
}

*::selection {
  background: #5efcd6;
  color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
