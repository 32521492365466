/* ----- Hide Scrollbard ------ */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Class to fix bug of ReactMultiEmail input field is always white */
.force-child-input-bg-transparent input {
  background: transparent;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

/* ----- Dark Scrollbar ------ */
.dark-scrollbar {
  padding-right: 8px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}

.dark-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dark-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.dark-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.dark-scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* ----- Light Scrollbar ------ */
.light-scrollbar {
  padding-right: 8px;
  scrollbar-width: thin;
  scrollbar-color: rgba(184, 188, 224, 0.5) transparent;
}

.light-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.light-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(184, 188, 224, 0.5);
  border-radius: 8px;
}

.light-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.light-scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* ----- Outline scrollbar ------ */
.outline-scrollbar {
  padding-right: 8px;
  scrollbar-width: thin;
  scrollbar-color: rgba(184, 188, 224, 0.5) transparent;
}

.outline-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.outline-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(14, 18, 47, 0.4);
  border-radius: 8px;
  border: 1px solid rgba(184, 188, 224, 0.4);
}

.outline-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.outline-scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* ----- Night7 Scrollbar------ */

.night7-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(14, 18, 47, 0.4) transparent;
}

.night7-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.night7-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(14, 18, 47, 0.4);
  border-radius: 8px;
  border: 1px solid rgba(184, 188, 224, 0.4);
}

.night7-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.night7-scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* ----- Night7 Scrollbar with padding ------ */

.night7-scrollbar-with-padding {
  margin-right: 8px;
  padding-bottom: 6.5rem;
  margin-top: 6.5rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(14, 18, 47, 1) transparent;
}

.night7-scrollbar-with-padding::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.touch-scroll {
  -webkit-overflow-scrolling: touch;
}

.touch-scroll video {
  pointer-events: none;
}

.night7-scrollbar-with-padding::-webkit-scrollbar-thumb {
  background-color: rgba(14, 18, 47, 1);
  border-radius: 8px;
  border: 1px solid rgba(184, 188, 224, 0.4);
  margin-bottom: 5rem;
}

.night7-scrollbar-with-padding::-webkit-scrollbar-track {
  background-color: transparent;
  margin-bottom: 7rem;
}

.night7-scrollbar-with-padding::-webkit-scrollbar-corner {
  background-color: transparent;
}
